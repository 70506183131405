// src/pages/ContactPage.js
import Header from '../components/Header';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import '../css/contact.css';
import { Helmet } from 'react-helmet';

// Function to dynamically import JSON ads and fix image paths
const importAll = (r) => {
  return r.keys().map((fileName) => {
    const ad = r(fileName);
    return {
      ...ad,
      slug: fileName.replace("./", "").replace(".json", ""),
      image: ad.image.replace(/^502culturestatic\//, ""), // Remove "502culturestatic/"
    };
  });
};

// Load ads from JSON files
const rectangularAds = importAll(require.context("../ads/rectangular", false, /\.json$/));
const squareAds = importAll(require.context("../ads/square", false, /\.json$/));

const ContactPage = () => {
  // Helper function to get a random ad
  const getRandomAd = (adsArray) => {
    return adsArray.length > 0 ? adsArray[Math.floor(Math.random() * adsArray.length)] : null;
  };

  // Helper function to get multiple unique ads
  const getUniqueAds = (adsArray, count) => {
    const uniqueAds = [];
    const adPool = [...adsArray];

    for (let i = 0; i < count && adPool.length > 0; i++) {
      const randomIndex = Math.floor(Math.random() * adPool.length);
      uniqueAds.push(adPool[randomIndex]);
      adPool.splice(randomIndex, 1);
    }

    return uniqueAds;
  };

  const bottomAd = getRandomAd(rectangularAds);
  const rightSideAds = getUniqueAds(squareAds, 3); // Get 3 unique square ads for the right side

  return (
    <div id="root">
      <Helmet>
        <title>Contact Us | 502Culture</title>
        <meta name="description" content="Contact 502Culture for inquiries, feedback, or collaboration. We’d love to hear from the Spokane cannabis community." />
      </Helmet>
      <Header />
      <main className="contact-main">
        <div className="ad-container">
          <div className="contact-content">
            {/* Contact Form */}
            <section className="contact-section">
              <Contact />
            </section>

            {/* Bottom Rectangular Ad */}
            {bottomAd && (
              <div className="cont-rect-ad-space">
                <a href={bottomAd.link} target="_blank" rel="noopener noreferrer">
                  <img src={bottomAd.image} alt={bottomAd.name} className="cont-rectangular-ad" />
                </a>
              </div>
            )}
          </div>

          {/* Right Side Square Ads */}
          <div className="side-ad-space">
            {rightSideAds.map((ad, index) => (
              <a key={`right-ad-${index}`} href={ad.link} target="_blank" rel="noopener noreferrer">
                <img src={ad.image} alt={ad.name} className="side-square-ad right" />
              </a>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ContactPage;