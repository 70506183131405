import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Calendar from '../components/Calendar';
import { Helmet } from 'react-helmet';
import '../css/homepage.css';

// Function to dynamically import JSON files and fix image paths
const importAll = (r) => {
  return r.keys().map((fileName) => {
    const item = r(fileName) || {};
    return {
      ...item,
      slug: fileName.replace("./", "").replace(".json", ""),
      image: item.image ? item.image.replace(/^502culturestatic\//, "") : "",
      link: item.link || "#",
      name: item.name || "Unnamed Ad",
    };
  });
};

// Load JSON data
const events = importAll(require.context("../events", false, /\.json$/));
const articles = importAll(require.context("../articles", false, /\.json$/));
const rectangularAds = importAll(require.context("../ads/rectangular", false, /\.json$/));
const squareAds = importAll(require.context("../ads/square", false, /\.json$/));

const HomePage = () => {
  const today = new Date();

  const upcomingEvents = events
    .filter(event => new Date(event.date) >= today)
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .slice(0, 5);

  const sliderRef = useRef(null);
  const sortedArticles = [...articles].sort((a, b) => new Date(b['date-uploaded']) - new Date(a['date-uploaded']));
  const recentArticles = sortedArticles.slice(0, 10);

  // Helper function to get unique ads
  const getUniqueAds = (adsArray, count) => {
    const shuffled = [...adsArray].sort(() => 0.5 - Math.random()); // Shuffle ads
    return shuffled.slice(0, count); // Take `count` unique ads
  };

  // Select unique ads
  const uniqueRectAds = getUniqueAds(rectangularAds, 2); // 2 rectangular ads
  const uniqueSquareAds = getUniqueAds(squareAds, 2); // 2 square ads

  const [topRectAd, bottomRectAd] = uniqueRectAds; // Rectangular ads (Top & Bottom)
  const [squareAd1, squareAd2] = uniqueSquareAds; // Square ads (Middle)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 640, settings: { slidesToShow: 1 } },
      { breakpoint: 940, settings: { slidesToShow: 2 } },
      { breakpoint: 1190, settings: { slidesToShow: 3 } },
    ],
  };

  return (
    <div id="root">
      <Helmet>
        <title>502Culture | Spokane's Cannabis News and Community</title>
        <meta name="description" content="Stay informed with the latest cannabis news, events, and community insights in Spokane. 502Culture brings together cannabis enthusiasts and industry professionals." />
      </Helmet>
      <Header />
      <main className="homepage-main">
        <section className="article-summary-section">
          <div className="recent-articles-header">
            <div className="custom-arrow left-arrow" onClick={() => sliderRef.current.slickPrev()}>
              <i className="bi bi-arrow-left-circle calendar-arrow"></i>
            </div>
            <h2 className='homepage-section-header'>Recent Articles</h2>
            <div className="custom-arrow right-arrow" onClick={() => sliderRef.current.slickNext()}>
              <i className="bi bi-arrow-right-circle calendar-arrow"></i>
            </div>
          </div>
          <hr />
          <div className="articles-container">
            <Slider ref={sliderRef} {...settings}>
              {recentArticles.map((article, index) => (
                <div key={index} className="article-summary">
                  <div className='img-cont'>
                    <img
                      src={article['image-path']}
                      alt={article.title}
                      className="article-image"
                    />
                  </div>
                  <h3>{article.title}</h3>
                  <p>{article.summary}</p>
                  <a href={`/all-articles#article-${index}`}>Read More</a>
                </div>
              ))}
            </Slider>
          </div>
        </section>

        {/* Top Rectangular Ad */}
        {topRectAd && (
          <div key='top-ad' className="rect-ad-space">
            <a href={topRectAd.link} target='_blank' rel="noopener noreferrer">
              <img src={topRectAd.image} alt={topRectAd.name} className='home-rectangular-ad' />
            </a>
          </div>
        )}

        <section className="special-events-section">
          <h2 className="homepage-section-header">Special Events</h2>
          <hr />
          <div className="events-container">
            {upcomingEvents.length > 0 ? (
              upcomingEvents.map((event, index) => (
                <div className="event-spotlight" key={index}>
                  <h3>{event.title}</h3>
                  <p><strong>Date:</strong> {new Date(event.date).toLocaleDateString()}</p>
                  <p><strong>Location:</strong> {event.location}</p>
                  <p><strong>Details:</strong> {event.description}</p>
                  {event.link && (
                    <a href={event.link} target="_blank" rel="noopener noreferrer" className="event-link">
                      Learn more
                    </a>
                  )}
                </div>
              ))
            ) : (
              <p>No upcoming events at the moment. Check back soon!</p>
            )}
          </div>
        </section>

        {/* Middle Square Ads */}
        <div key='square-ads' className="dual-ads">
          {squareAd1 && (
            <a href={squareAd1.link} target="_blank" rel="noopener noreferrer">
              <img src={squareAd1.image} alt={squareAd1.name} className='dual-ad' />
            </a>
          )}
          {squareAd2 && (
            <a href={squareAd2.link} target="_blank" rel="noopener noreferrer">
              <img src={squareAd2.image} alt={squareAd2.name} className='dual-ad' />
            </a>
          )}
        </div>

        <section className="calendar-section">
          <h2 className='homepage-section-header'>Event Calendar</h2>
          <hr />
          <Calendar />
        </section>

        {/* Bottom Rectangular Ad */}
        {bottomRectAd && (
          <div key='bottom-ad' className="rect-ad-space">
            <a href={bottomRectAd.link} target='_blank' rel="noopener noreferrer">
              <img src={bottomRectAd.image} alt={bottomRectAd.name} className='home-rectangular-ad' />
            </a>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;