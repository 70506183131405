import React, { forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';

const formatDate = (isoDate) => {
  return isoDate ? isoDate.split("T")[0] : "Unknown Date";
};

const ArticleCard = forwardRef(({ title, content, author, publishDate, category, downloadLink }, ref) => {
  return (
    <div className="article-card" ref={ref}>
      <h2>{title}</h2>
      <p><strong>Author:</strong> {author}</p>
      <p><strong>Published on:</strong> {formatDate(publishDate)}</p>
      <p><strong>Category:</strong> {category}</p>
      {/* Use ReactMarkdown to render formatted text */}
      <div className="article-text">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
      {downloadLink && <a href={downloadLink} style={{ margin: 0 }} download>Download Article</a>}
    </div>
  );
});

export default ArticleCard;
