import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ArticleCard from '../components/ArticleCard';
import ArticleFilter from '../components/ArticleFilter';
import { Helmet } from 'react-helmet';

// Function to dynamically import JSON files and fix image paths
const importAll = (r) => {
  return r.keys().map((fileName) => {
    const item = r(fileName) || {}; // Ensure item is defined
    return {
      ...item,
      slug: fileName.replace("./", "").replace(".json", ""),
      image: item.image ? item.image.replace(/^502culturestatic\//, "") : "", // Only replace if image exists
      link: item.link || "#", // Provide a default link to prevent errors
      name: item.name || "Unnamed Ad", // Provide a default name
    };
  });
};

// Load data from JSON files
const articlesData = importAll(require.context("../articles", false, /\.json$/));
const rectangularAds = importAll(require.context("../ads/rectangular", false, /\.json$/));
const squareAds = importAll(require.context("../ads/square", false, /\.json$/));


const AllArticles = () => {
  const [filteredArticles, setFilteredArticles] = useState(articlesData);
  const articleRefs = useRef([]); // Array of refs for each article

  const authors = Array.from(new Set(articlesData.map(article => article.author)));
  const categories = Array.from(new Set(articlesData.map(article => article.category)));

  // Shuffle an array randomly
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const [adQueue] = useState(shuffleArray([...rectangularAds])); // Shuffle rectangular ads
  const [randomSquareAd] = useState(squareAds[Math.floor(Math.random() * squareAds.length)]); // Pick a random square ad

  const applyFilters = (filterInputs) => {
    const { title, author, category } = filterInputs;

    const filtered = articlesData.filter(article =>
      (title === '' || article.title.toLowerCase().includes(title.toLowerCase())) &&
      (author === '' || article.author === author) &&
      (category === '' || article.category === category)
    );
    setFilteredArticles(filtered);
  };

  const insertAds = (articles) => {
    let articlesWithAds = [];
    articles.forEach((article, index) => {
      articlesWithAds.push(
        <ArticleCard
          key={`article-${index}`}
          ref={(el) => articleRefs.current[index] = el}
          title={article.title}
          content={article.content}
          author={article.author}
          publishDate={article.publishDate}
          category={article.category}
          downloadLink={article.downloadLink}
        />
      );

      // 50% chance to insert an ad after each article
      if (Math.random() > 0.5 && adQueue.length > 0) {
        const ad = adQueue[index % adQueue.length];

        articlesWithAds.push(
          <div key={`rect-ad-${index}`} className="rect-ad-space">
            <a href={ad.link} target="_blank" rel="noopener noreferrer">
              <img src={ad.image} alt={ad.name} className='rectangular-ad'/>
            </a>
          </div>
        );
      }
    });
    return articlesWithAds;
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const articleIndex = parseInt(hash.replace('#article-', ''), 10);
      if (!isNaN(articleIndex) && articleRefs.current[articleIndex]) {
        articleRefs.current[articleIndex].scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div id="root">
      <Helmet>
        <title>All Articles | 502Culture</title>
        <meta name="description" content="Browse all cannabis-related articles and news in Spokane by 502Culture. Discover stories about local cultivators, suppliers, and community events." />
      </Helmet>
      <Header />
      <main>
        <div className="content-container">
          <div className="filter-section">
            <div className="filter-box">
              <ArticleFilter
                onFilterApply={applyFilters}
                authors={authors}
                categories={categories}
              />
            </div>
            {/* Dynamic Square Ad Space */}
            <div className="ad-space">
              {randomSquareAd && (
                <a href={randomSquareAd.link} target="_blank" rel="noopener noreferrer">
                  <img src={randomSquareAd.image} alt={randomSquareAd.name} className='all-articles-ad'/>
                </a>
              )}
            </div>
          </div>
          <div className="article-list">
            {filteredArticles.length > 0 ? (
              insertAds(filteredArticles)
            ) : (
              <div className="no-articles-message">No results found</div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AllArticles;
